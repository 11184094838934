@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.animate {
    position: fixed;
    z-index: 1;
    // left: 0;
    // top: 0;
    width: 100vw;
    height: 100vh;
    margin-left: 45%;
    margin-top: 5rem;
    overflow: hidden;
    background-color: transparent;
    // opacity: 0.85;
    font-size: 5rem;
    animation-name: mail;
    animation-duration: 2s;
}

@keyframes mail {
    0% {
        top: 0px;
    }
    50% {
        top: 200px;
    }
    100% {
        top: 0px;
        opacity: 0.5;
    }
}

.QuizM {
    // @extend .Component;
    text-align: center;
    background: linear-gradient(-45deg, #eaafc8, $light-purple, $light-blue, #654ea3);
    height: 100vh;
    // min-width: none;
    // max-width: none;
    width: 100%;
    margin: 0;
    padding-top: 2rem;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;

    .select {
        @extend .select;
        background-color: hsla(0, 0%, 96%, 0.6);
        min-height: 35rem;
        width: 65%;
        min-width: 58rem;
        margin: auto;
    }

    h4 {
        font-family: "Solway", serif;
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }

    .GetItem {
        display: flex;
        flex-direction: column;
        // justify-content: space-evenly;
        width: 90%;
        .bet,
        .time {
            border: 4px double white;
            border-radius: 1rem;
            .title {
                background-color: whitesmoke;
                border-radius: 1rem;
                margin: -0.9rem auto auto;
                width: 70%;
                color: $dark-purple;
            }
        }
        .bet {
            margin-bottom: 1rem;
            padding: 0.5rem;
            .title {
                margin: -1.4rem auto auto;
            }
            .points-to-bet {
                margin: 0.75rem;

                .minus,
                .plus {
                    border-radius: 0;
                    width: 2.5rem;
                    margin: 0;
                    padding: 0;
                    font-size: 1.25rem;
                    height: 2.5rem;
                    padding-bottom: 0.25rem;
                }
                .minus {
                    border-bottom-left-radius: 1rem;
                    border-top-left-radius: 1rem;
                }
                .plus {
                    border-bottom-right-radius: 1rem;
                    border-top-right-radius: 1rem;
                }
                .points {
                    display: inline-block;
                    width: 4rem;
                    height: 2.5rem;
                    border: 4px double $dark-purple;
                    color: $dark-purple;
                    font-weight: bold;
                    font-family: "Solway", serif;
                    // padding: 0.5rem;
                    font-size: 1.25rem;
                    // background-color: hsla(0, 0%, 96%, 0.75);
                }
            }
        }
        .time {
            .countdown {
                font-size: 3rem;
                color: $dark-purple;
                text-align: center;
                .count {
                    font-family: "Solway", serif;
                }
                .icon {
                    font-size: 2.5rem;
                }
            }
        }
    }

    .question {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;
        justify-items: center;
        margin-top: 2rem;
        margin-bottom: 3rem;
        height: 18rem;

        .explanation {
            grid-column: 1;
            font-family: "Solway", serif;
            font-size: 1rem;
            background-color: #dadada;
            padding: 1rem;
            border-radius: 1rem;
            float: left;
            width: 22rem;
            height: 19.5rem;
            overflow: hidden;

            .pages {
                display: inline-block;
                b {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }

            .gallery {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }

        .card-container {
            grid-column: 2;
            // width: 20rem;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-top: 2.5rem;
            // border-left: 2px dashed white;
            // border-right: 2px dashed white;
        }

        .choices {
            list-style-type: none;
            font-weight: bold;
            font-size: 1.1rem;
            margin-top: 3rem;

            li {
                padding-top: 0.75rem;
                width: 10rem;
                color: white;

                .unchecked,
                .checked,
                .correct,
                .incorrect {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    border-radius: 7px;
                    cursor: pointer;
                }
                .unchecked:hover,
                .checked:hover {
                    background-color: $light-purple;
                }
                .unchecked {
                    background-color: hsl(0, 0%, 75%);
                }
                .checked {
                    background-color: $light-blue;
                }
                .correct {
                    background-color: #48c984;
                }
                .incorrect {
                    background-color: #ff3b3b;
                }
                width: 12rem;
            }
        }
    }

    .choices {
        @extend .list-style;
        font-family: "Solway", serif;
        font-size: 1.5rem;
        grid-column: 3;
        justify-self: center;
        li {
            width: 13rem;
        }
    }

    .quizhead {
        h3 {
            @extend .header;
            text-align: left;
            font-size: 1.5rem;
            padding-bottom: 0.5rem;
            border-bottom: 2px solid $dark-purple;
        }
        .hint {
            width: 1rem; // make smaller - add an icon too
        }
        .score {
            font-family: "Solway", serif;
            font-size: 1.5rem;
            font-weight: bold;
            color: $dark-purple;
            margin-bottom: -1.2rem;
        }
        .test-game {
            text-align: center;
            margin: auto;
            button {
                margin: 1rem;
            }
        }

        .next {
            background-color: $light-blue;
        }

        .next-correct {
            background-color: #48c984;
        }
    }
    .continue {
        border-top: solid 2px $dark-purple;
        padding-top: 1rem;
        button {
            @extend .blueButton;
        }
    }
}

.Frozen-Quiz {
    @extend .QuizM;
    pointer-events: none;
    opacity: 0.5;

    .select {
        background-color: lightblue;
    }
}

.Inventory {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    list-style: none;
    border: 1px solid $light-blue;
    background-color: whitesmoke;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 5rem;
    margin: auto;
    width: 6rem;
    height: 12rem;
    .item {
        margin: auto;
        border-radius: 50%;
        height: 5rem;
        width: 5rem;
        background-color: $lighter-blue;
        border: 5px double whitesmoke;
        cursor: pointer;
        &:hover {
            opacity: 80%;
        }
        .lock {
            font-size: 1.5rem;
            margin-left: 2rem;
            transform: translateY(-25px);
        }
        .icon,
        .name {
            text-align: center;
        }
        .icon {
            font-size: 2rem;
        }
        .name {
            font-size: 0.75rem;
        }
    }
}
