@import "./theme/colors";
@import "./theme/layout";
@import "./theme/flashcard";

.spin-flashcard {
    transition: transform 0.25s linear;
    transform-origin: center;
    transform: rotate(180deg);
}

.FlashcardForms {
    // ADD/DELETE MODE
    .raw,
    .unselected,
    .selected,
    .delete {
        @extend .flashcard;
        &:hover {
            border: 0.65rem solid $light-purple;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
    }

    .pronoun {
        font-size: 1rem;
    }

    sub {
        font-size: 0.65rem;
    }

    .unselected:hover {
        transform: rotate(-1deg);
    }

    .selected {
        background-color: #dcffd0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        transform: rotate(-2deg);
    }

    .delete {
        background-color: $delete;
        color: whitesmoke;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        transform: rotate(2deg);
        .upper {
            color: whitesmoke;
        }
        hr {
            border-top: 2px dashed whitesmoke;
        }
    }

    // FLIP FORM
    .flip,
    .flip2 {
        &:hover .card {
            transform: rotateY(180deg);
            transition: transform 0.6s ease-in-out;
        }

        .card {
            transition: transform 0.3s ease-in-out;
            transform-style: preserve-3d;
            border: none;
            width: 22.5rem;
            height: 12rem;

            figure {
                @extend .flashcard;
                width: 100%;
                height: 100%;
                position: absolute;
            }

            .back {
                font-size: 2.5rem;
                color: $dark-purple;
                border-color: $light-purple;
                backface-visibility: hidden;
                font-weight: bold;
                transform: rotateY(180deg);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .front2 {
                font-size: 2.5rem;
                color: $dark-purple;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .back2 {
                border-color: $light-purple;
                transform: rotateY(180deg);
                backface-visibility: hidden;
            }
        }
    }

    .raw,
    .conjugate,
    .identify_tense {
        width: 22.5rem;
    }

    .badfont {
        font-size: 15px;
        letter-spacing: -2px;
    }

    .conjugate,
    .identify_tense {
        @extend .flashcard;
    }

    .is-correct {
        border-color: $correct-green;
    }

    .is-incorrect {
        border-color: $incorrect-red;
    }

    // CONJUGATE
    .conjugate {
        div {
            margin-top: 0.5rem;
            margin-bottom: 1rem;
        }
        .form {
            font-weight: bold;
        }

        .blank {
            display: inline-block;
            margin-bottom: 0.5rem;
            .showAnswer {
                margin-bottom: -0.2rem;
                color: green;
            }
            .verbInput,
            .wrongInput,
            .rightInput,
            .hintInput {
                font-weight: bold;
                margin-left: 1rem;
                width: 10rem;
                display: inline-block;
            }

            .hintInput {
                color: $light-blue;
            }

            .measureWord {
                background: #feb200;
                margin-left: 9rem;
                transform: translateY(-30px);
                color: black;
                position: absolute;
                padding: 0 0.5rem;
                border-radius: 0.5rem;
                z-index: 3;
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: 100%;
                    margin-top: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent #feb200 transparent transparent;
                }
            }
        }
        .down {
            font-size: 3rem;
        }
    }

    .wrongInput {
        text-decoration: line-through;
        color: red;
    }
    .skipped {
        display: inline-block; // removes line through
        color: lightcoral;
    }
    .rightInput {
        color: green;
    }

    // TENSE
    .identify_tense {
        svg {
            color: $light-blue;
            font-size: 3rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        // .transition-arrow {
        //     display: flex;
        //     flex-direction: row;
        // }
    }
}
