@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Home {
    @extend .Component;
    margin-top: 1rem;

    button {
        @extend button;
        font-weight: bold;
    }

    // your collections or explore
    .view {
        margin: auto;
        margin-bottom: 1rem;
        width: fit-content;
        background-color: whitesmoke;
        border-radius: 0.5rem;
        padding: 0.75rem 0.1rem;
        font-weight: bold;
        .viewOption {
            margin: 0.5rem;
            padding: 0.5rem;
            border-radius: 0.5rem;
            &:hover {
                background-color: $lighter-blue;
                cursor: pointer;
            }
        }
    }

    // For getting out of editing collection/deck names
    .backButton {
        color: $dark-purple;
        font-size: 1rem;
        margin-left: -1rem;
        margin-right: 0.5rem;
        &:hover {
            cursor: pointer;
        }
    }

    // Textbox for adding/editing deck names
    .deckNameInput {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
            "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        width: 72%;
        margin-top: 3.5rem;
        font-size: 1.25rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .addCollection {
        position: relative;
        width: 40%;
        padding: 2rem 0 5rem 2rem;
        input {
            @extend input;
            font-weight: bold;
            position: absolute;
            max-width: 75%;
        }
        .lock,
        .addButton {
            right: 0;
            top: 20%;
            position: absolute;
            padding: 1rem;
            width: 4rem;
            height: 4rem;
            cursor: pointer;
        }
        .lock {
            color: #dadada;
            margin-right: 4.5rem;
            &:hover {
                color: $light-purple;
            }
        }
    }

    .expand {
        text-align: center;
        margin-top: -0.5rem;
        border-top: solid 2px #dadada;
        .addButton {
            margin-top: 0.75rem;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0.4rem;
        }
    }

    .addButton {
        @extend .icon-button;
        width: 7rem;
        height: 7rem;
    }
}
