@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Auth {
    text-align: center;
    border-radius: 1rem;
    background-color: whitesmoke;
    padding: 1rem;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 20rem;

    a {
        color: $light-blue;
        &:hover {
            color: $light-blue;
        }
    }
    
    div {
        padding: 0.5rem;
    }

    .link {
        font-size: 0.85rem;
    }

    h2 {
        @extend .header;
        padding: 1rem;
    }

    label {
        float: left;
    }

    input {
        width: 100%;
        height: 2.5rem;
        font-size: 1rem;
        margin-bottom: 1rem;
        background-color: $light-purple;
        color: black;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    select {
        font-size: 1rem;
        border-radius: 0.25rem;
        margin-left: 1.25rem;
        padding-left: 0.25rem;
        padding-bottom: 0.2rem;
        border: 0;
    }
    
    button {
        @extend button;
        font-weight: bold;
    }
}