@import "./theme/colors";
@import "./theme/layout"; 
@import "./theme/flashcard"; 
@import "./theme/input"; 

.DeckSelection {

    ::-webkit-scrollbar {
        width: 15px;
    }
    
    ::-webkit-scrollbar-track {
        background-color: whitesmoke; 
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: lightgrey;
        border-radius: 20px;
        border: 0.4rem solid transparent; 
        background-clip: padding-box;
    }

    text-align: center;

    .addCards {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 3rem;
    }

    .deleteCards {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        margin: 2rem;

        .deleteFrom {
            background: $salmon;
            color:$delete;
            border-color: $delete;
            &:hover {
                background:$delete;
                color: $salmon;
                border-color: $delete;
            }
        }
    }

    .addCards, .deleteCards {
        .saveMessage {
            padding: 1rem;
            width: 50%;
            border-radius: 1rem;
            margin-bottom: 0.5rem;
            background-color: $light-purple;
            color: $dark-purple;
            font-weight: bold;
        }
        .deckSelection { // add a slide up animation here
            //margin-right: 3rem;
            background-color: whitesmoke;
            margin-bottom: 1rem;
            width: 16rem;
            box-shadow: 0 4px 8px rgba(0.2, 0.2, 0.2, 0.2); // maybe choose a different background color and get rid of shadow
            border-radius: 1rem;
            .collectionSelection {
                
                .location {
                    border-top-left-radius: 1rem;
                    border-top-right-radius: 1rem;
                    background-color: $light-purple;
                    font-size: 1.3rem;
                    font-weight: bold;
                    color: $dark-purple;
                    padding-bottom: 0.5rem;
                    .point {
                        color: hsl(0, 0%, 75%);
                        font-size: 1.75rem;
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                        margin-top: 0.5rem;
                    }
                }
            }
            .deckList {
                @extend .list-style;
                max-height: 15rem;
                width: 14rem;
                padding-left: 2rem;
                margin: auto;
                overflow: auto;
            }
            .newDeck {
                display: flex;
                justify-content: center;
                height: 3rem;
                margin-top: 1rem;
                input {
                    @extend input;
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
                    width: 75%;
                    height: 2rem;
                    margin-right: 0.5rem;
                    font-size: 1.25rem;
                    background-color: $light-purple;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
                .addDeck {
                    color: lightgrey;
                    font-size: 1.75rem;
                    cursor: pointer;
                    &:hover {
                        color: $light-purple;
                    }
                }
            }
            //scrollbar
        }
        .btn-group {
            width: 16rem;
            justify-content: center;
            .addTo, .deleteFrom, .openSelection {
                @extend button;
                font-size: 1.25rem;
                padding: 1rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                border-radius: 2rem;
            }
            .addTo:hover, .openSelection:hover {
                background-color: $light-purple;
                color: $dark-purple;
            }
            .addTo {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            .openSelection {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            @mixin unactiveHover {
                opacity: 0.6;
                &:hover {
                    background-color: whitesmoke;
                    color: $dark-purple;
                }
            }

            .unactiveAddTo {
                @extend .addTo;
                @include unactiveHover;
            }
        }

        select {
            margin: auto;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            font-size: 1.25rem;
            //font-weight: bold;
            width: 75%;
            border-radius: 5px;
            padding: 0.4rem;
            option {
                //font-weight: bold;
            }
        }
    }
}