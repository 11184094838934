@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.deckDetail {
    @extend .select;
    @extend .Component;
    margin: auto;
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    overflow: hidden;
    background-color: $light-purple;

    .deckTitle {
        flex: 0 1 25%;
        h2,
        h4 {
            @extend .header;
            text-align: center;
        }
        h4 {
            border-top: 3px solid $dark-purple;
            font-size: 1.2rem;
            padding-top: 0.5rem;
        }
    }

    .quizzes {
        flex: 0 1 60%;
        margin-left: auto;
        h5 {
            font-size: 1.75rem;
            color: $dark-purple;
        }

        .instruction {
            font-size: 1rem;
            margin-top: 1rem;
        }

        button {
            @extend .blueButton;
            margin-right: 1rem;
            margin-top: 0.5rem;
        }
    }
}
