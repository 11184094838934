@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Results {
    @extend .select;
    @extend .Component;
    text-align: center;
    h3 {
        font-size: 1.5rem;
    }
    ol {
        margin: 2rem auto;
        list-style: none;
        li {
            margin-bottom: 2rem;
            .FlashcardForms {
                display: flex;
                justify-content: center;
            }
        }
    }
    button {
        border-radius: 0.5rem;
    }
}
