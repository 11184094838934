@import "./colors";

// General page layouts that are commonly used

// Ususally for parent component
.Component {
    text-align: left;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 70rem;
    min-width: 40rem;
}

.select {
    background-color: whitesmoke;
    border-radius: 1rem;
    padding: 1rem;
    // margin: 0 1rem 2rem 1rem;
}

.header {
    font-family: "Solway", serif;
    color: $dark-purple;
}

.header-b {
    @extend .header;
    font-weight: bold;
}

.overlay {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $light-blue-overlay;
}

.DSoverlay {
    @extend .overlay;
    overflow-y: scroll;
    // flex-direction: column;
    background-color: $light-purple-overlay;
    margin: auto;
    padding: 4rem;
}

.list-style {
    list-style-type: none;
    font-weight: bold;
    font-size: 1.1rem;

    li {
        padding-top: 0.75rem;
        width: 10rem;
        color: white;

        .unchecked,
        .checked,
        .correct,
        .incorrect {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            border-radius: 7px;
            cursor: pointer;
        }
        .unchecked:hover,
        .checked:hover {
            background-color: $light-purple;
        }
        .unchecked {
            background-color: hsl(0, 0%, 75%);
        }
        .checked {
            background-color: $light-blue;
        }
        .correct {
            background-color: #48c984;
        }
        .incorrect {
            background-color: #ff3b3b;
        }
    }
}
