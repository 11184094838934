@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Rules {
    background-color: hsla(0, 0%, 96%, 0.8);
    margin: auto;
    padding: 1.5rem;
    border-radius: 1rem;
    max-width: 65%;
    overflow: auto;
    .head {
        border-bottom: 2px solid $dark-purple;
        svg {
            font-size: 2rem;
        }
    }
    .how-to-play {
        padding: 1rem 0.5rem;
    }
    .tier-row {
        display: flex;
        margin: 1rem 0 1rem;
        padding-bottom: 1rem;
        border-bottom: 2px dashed $light-blue-overlay;
        .header {
            align-self: center;
            min-width: 4rem;
        }
        .items {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .item {
                background-color: $light-purple;
                border-radius: 0.5rem;
                padding: 0.5rem;
                text-align: center;
                .icon {
                    font-size: 2rem;
                    svg {
                        filter: drop-shadow(-4px -6px lightgreen);
                    }
                }
                .name {
                    font-weight: bold;
                }
                .description {
                    font-size: 0.85rem;
                    width: 150px;
                    color: $dark-purple;
                }
            }
        }
    }

    .legend {
        display: flex;
        margin: 0.5rem 0.5rem 0;
        font-weight: bold;
    }

    .box {
        height: 15px;
        width: 15px;
        border-radius: 3px;
        margin: 0.35rem 0.5rem 0;
    }

    .red {
        background-color: lightcoral;
    }

    .green {
        background-color: lightgreen;
    }
}

@media (max-width: 1000px) {
    .Rules {
        max-width: 95%;
    }
}
