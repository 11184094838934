@import "./theme/colors";
@import "./theme/layout"; 

.Linguistics {
    @extend .Component;

    .select {
        @extend .select;
    }

    h4 {
        font-family: 'Solway', serif;
        color: #4b0082;
    }

    p {
        margin-left: 1rem;
        background-color: #663d83;
        padding: 0.5rem;
        border-radius: 10px;
        color: white;
    }
}  