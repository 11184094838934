@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.ClassroomGrid {
    @extend .Component;
    @extend .select;
    flex-direction: column;

    margin: auto;
    margin-top: 2rem;
    margin-bottom: 0;
}

.ClassroomGrid-overlay {
    @extend .overlay;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

button {
    @extend button;
}

.head {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px lightgrey solid;
    svg {
        color: lightgrey;
        cursor: pointer;
        &:hover {
            color: $light-purple;
        }
    }
    .title {
        display: flex;
        svg {
            font-size: 2.5rem;
            margin: 0.5rem;
        }
        h2 {
            @extend .header;
            font-weight: bold;
            padding: 0.6rem 1rem 0.5rem 0.5rem;
        }
    }
    .classroom-details {
        svg {
            font-size: 2rem;
            margin: 0.75rem;
        }
    }
}

.title-inc {
    font-family: "Solway", "serif";
    color: $dark-purple;
    text-align: center;
    margin-top: 1rem;
    text-decoration: underline;
}

.incorrect-correct {
    @extend .Component;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 6rem;
    span {
        font-weight: bold;
    }
}

.check,
.exit {
    font-size: 2.5rem;
    margin-left: 1rem;
    cursor: pointer;
}

.check {
    color: $light-green;
    &:hover {
        color: lightgreen;
    }
}

.exit {
    color: $dark-purple;
    align-self: flex-end;
    margin-right: 15rem;
}

.addCollectionToClass {
    display: flex;
}

.instruction {
    font-family: "Solway", "serif";
    background-color: whitesmoke;
    color: $dark-purple;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

// Generalize
.classrooms-container {
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    min-height: 14rem;
    padding: 1rem;
    .classroom {
        height: 14rem;
        background-color: $light-grey;
        overflow: hidden;
        cursor: pointer;
        padding: 1rem 2rem;
        border-radius: 1rem;
        &:hover {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        }
        .name {
            font-family: "Solway", "serif";
            font-weight: bold;
            color: $dark-purple;
            font-size: 1.6rem;
            border-bottom: 2px lightgrey solid;
        }
        .code {
            margin-top: 0.5rem;
        }
    }
    .chosen-classroom {
        @extend .classroom;
        background-color: $light-purple;
    }
    .no-classes {
        grid-column-start: 2;
        margin: auto;
    }
}

.create-class {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    input {
        margin-right: 1rem;
    }

    .cancel {
        margin-left: 5rem;
    }
}

.classroom-window {
    min-height: 30rem;
    padding: 1rem;

    select {
        margin: 1rem;
        min-width: 15rem;
        font-family: "Solway", "serif";
        font-weight: bold;
        color: $dark-purple;
        font-size: 1.25rem;
        padding: 0.5rem;
        border: 0;
        border-bottom: 2px solid $dark-purple;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        background: #ebebeb;
    }

    .overall-stats {
        display: flex;
        border-radius: 1rem;
        margin-top: 2rem;

        th {
            border: 3px solid white;
            padding: 0.5rem;
            text-align: center;
        }
    }

    .table-wrapper {
        width: 100%;
        overflow-x: auto;
    }

    .table-wrapper > table {
        margin-left: 150px;
    }

    .table-wrapper > table > * > tr > th:first-child,
    .table-wrapper > table > * > tr > td:first-child {
        position: absolute;
        width: calc(150px + 3px);
        transform: translateY(-1px);
        margin-left: calc(-150px);
        background: lightgrey;
        border: 3px solid white;
    }

    .table-wrapper > table > * > tr > th,
    .table-wrapper > table > * > tr > td {
        /* Without this, if a cell wraps onto two lines, the first column will look bad, and may need padding. */
        white-space: nowrap;
        text-align: center;
        border: 3px solid white;
        padding: 0.5rem;
    }

    table {
        margin-top: 1rem;
        margin-bottom: 1rem;

        th {
            background-color: lightgrey;
            font-family: "Solway", "serif";
            min-width: 150px;
        }

        .username {
            font-weight: bold;
        }

        .stat:hover {
            background-color: $light-purple;
            cursor: pointer;
        }
    }

    // table tr:last-child td:first-child {
    //     border-bottom-left-radius: 10px;
    // }

    // table tr:last-child td:last-child {
    //     border-bottom-right-radius: 10px;
    // }

    .rename {
        margin-top: 2rem;
        margin-bottom: 8rem;
    }

    .buttons {
        display: flex;
        flex-direction: row;
    }
}
