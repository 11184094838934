@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.yourCollections,
.otherCollections {
    @extend .select;
    @extend .Component;
}

.otherCollections {
    margin-top: -1rem;
}

.versus {
    .head {
        border-bottom: 2px solid $dark-purple;
        .header-b {
            margin-left: 1rem;
            margin-top: 0.25rem;
        }
        svg {
            font-size: 2rem;
            margin-bottom: 0.5rem;
        }
        button {
            border-radius: 0.5rem;
            margin-bottom: 0.5rem;
        }
    }
}

.collection {
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    .collectionName,
    .collectionNameEdit {
        padding: 0.75rem 2rem 0.5rem 1rem;
        margin-bottom: 0.5rem;
    }
    .collectionName {
        display: inline-block;
        border-radius: 50px;
        &:hover {
            background-color: $light-purple;
        }
        &:hover .options {
            margin-left: 3rem;
            display: block;
            float: right;
            font-size: 1.5rem;
            color: $dark-purple;
            cursor: pointer;
            svg {
                color: white;
                margin-left: 1.5rem;
                &:hover {
                    color: $dark-purple;
                }
            }
        }
        .options,
        .editMode {
            display: none;
        }
        h2 {
            @extend .header;
            float: left;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .collectionNameEdit {
        .options,
        h2 {
            display: none;
        }
        .editMode {
            display: flex;
            .backButton {
                font-size: 1.5rem;
                margin-top: 1.05rem;
            }
            .collectionNameInput {
                @extend input;
                font-weight: bold;
            }
            .checkButton {
                @extend .icon-button;
                width: 4rem;
                height: 4rem;
                padding: 1rem;
                margin-left: 0.5rem;
            }
        }
    }

    .decks {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 1rem;
        min-height: 14rem;
        .deck,
        .clicked {
            height: 14rem;
            position: relative;
            border-radius: 1rem;
        }
        .deck {
            background-color: $light-grey;
            overflow: hidden;
            cursor: pointer;
            &:hover {
                background-color: $light-purple;
                .deckIcon .none {
                    background: #a78aff;
                    box-shadow: inset 0 0 30px $light-purple;
                }
                .deckName {
                    color: $dark-purple;
                }
            }
            &:hover .options {
                display: block;
                font-size: 1.7rem;
                padding-right: 1rem;
                margin-top: -10rem;
                text-align: right;
                svg {
                    padding-top: 0.5rem;
                    color: white;
                    &:hover {
                        color: $dark-purple;
                    }
                }
            }
            .deckIcon {
                margin: auto;
                text-align: center;
                img {
                    padding-top: 1rem;
                    width: 45%;
                    &:hover {
                        opacity: 0.5;
                    }
                }
                .none {
                    margin: auto;
                    margin-top: 8rem;
                    width: 120px;
                    height: 40px;
                    border-radius: 50%;
                    background: rgb(156, 156, 156);
                    box-shadow: inset 0 0 30px #ebebeb;
                }
            }
            .options {
                display: none;
            }
            .deckName,
            .deckNameEdit {
                position: absolute;
                bottom: 0;
                font-size: 1.25rem;
                text-align: center;
            }
            .deckName {
                width: 100%;
                margin-bottom: 1rem;
            }
        }
        .unclicked {
            position: relative;
            text-align: center;
            .deckNameInput,
            .backButton {
                display: none;
            }
            .addButton {
                margin-top: 3rem;
                width: 7rem;
                height: 7rem;
                color: white;
            }
        }
        .clicked {
            text-align: center;
            background-color: $light-purple;
            .backButton {
                position: absolute;
                left: 0;
                margin-top: 1rem;
                margin-left: 1rem;
                color: whitesmoke;
                font-size: 1.25rem;
                cursor: pointer;
                &:hover {
                    color: $dark-purple;
                }
            }
            .addButton {
                transform: translate(0%, 43%);
                color: white;
                background-color: $light-purple;
                &:hover {
                    background-color: #c7b5ff;
                }
            }
        }
    }
}

.addCollection {
    position: relative;
    width: 40%;
    padding: 2rem 0 5rem 2rem;
    input {
        @extend input;
        font-weight: bold;
        position: absolute;
        max-width: 75%;
    }
    .lock,
    .addButton {
        right: 0;
        top: 20%;
        position: absolute;
        padding: 1rem;
        width: 4rem;
        height: 4rem;
        cursor: pointer;
    }
    .lock {
        color: #dadada;
        margin-right: 4.5rem;
        &:hover {
            color: $light-purple;
        }
    }
}

.expand {
    text-align: center;
    margin-top: -0.5rem;
    border-top: solid 2px #dadada;
    .addButton {
        margin-top: 0.75rem;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.4rem;
    }
}

.addButton {
    @extend .icon-button;
    width: 7rem;
    height: 7rem;
}
