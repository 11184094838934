@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.FlashcardGrid {
    @extend .Component;

    .tabs {
        @extend .select;
        margin-bottom: -1rem;
        background-color: transparent;

        .tab {
            border-radius: 1rem;
            text-align: center;
            width: auto;

            background-color: lightgrey;
            color: $indigo;
            display: inline-block;
            font-weight: bold;

            padding-left: 2rem;
            padding-right: 2rem;
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            cursor: pointer;

            &:hover {
                background-color: $light-purple;
            }
        }

        .selectedTab {
            @extend .tab;
            background-color: whitesmoke;
            box-shadow: -5px 0 0 -10px #0000001a; // make it work not on bottom though
            &:hover {
                background-color: whitesmoke;
            }
        }
    }

    // TOOLBAR
    .toolbar {
        @extend .select;
        margin-top: 0;
        text-align: left;
        padding-left: 2rem;
        padding-right: 2rem;
        height: 5rem;
        overflow: hidden;

        .viewOptions,
        .editMode,
        .studyModes {
            display: inline-block;
        }

        .studyModes,
        .editMode {
            display: flex;
            button {
                @extend button;
                margin-right: 1rem;
                min-width: 10rem;
                width: auto;
            }
        }

        .flipOptions {
            text-align: right;
        }

        .studyModes {
            .mode {
                background-color: $light-purple;
                &:hover {
                    color: $light-purple;
                }
            }
            .flipAll {
                margin-left: auto;
                @extend .blueButton;
            }
        }

        .editMode {
            .selectAll {
                border-color: #48c984;
                color: #48c984;
                background-color: hsl(105, 100%, 91%);
                &:hover {
                    border-color: #48c984;
                    color: #dcffd0;
                    background-color: #48c984;
                }
            }
            .deselectAll {
                border-color: grey;
                color: grey;
                background-color: lightgrey;
                &:hover {
                    border-color: grey;
                    color: lightgrey;
                    background-color: grey;
                }
            }
            .addCards {
                margin-left: auto;
            }
        }
    }

    // GRID FORMAT
    .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 6rem;
    }
}
