@import "./colors";

// Common style of buttons and textboxes

// Make a second type of button for logout and the ones you see in the quiz

button {
    font-weight: bold;
    border-radius: 0.5rem;
    border-width: 1.25px;
    border-style: solid;
    padding: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-color: whitesmoke;
    border-color: $dark-purple;
    color: $dark-purple;
    transition: 0.2s;
    &:hover {
        border-width: 1.25px;
        color: whitesmoke;
        background-color:$dark-purple;
        border-color: $dark-purple;
    }
}

.greyButton {

}

.blueButton {
    @extend button;
    background-color: $light-blue;
    color: $indigo;
    border-width: 0;
    width: 10rem;
    &:hover {
        background-color: $light-blue;
        opacity: 0.75;
        color: white;
        border-width: 0;
    }
}

// .btn-group {
//     button {
//         margin: 0;
//     }
// }

.icon-button {
    background-color: #dadada;
    border-radius: 50%;
    padding: 1.5rem;
    color: whitesmoke;
    cursor: pointer;
    &:hover {
        background-color: $light-purple;
    }
}

// Call this the big input maybe?
input {
    font-family: 'Solway', serif;
    font-size: 2rem;
    padding-left: 0.75rem;
    background-color: transparent;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    background-color: whitesmoke;
    border-color: $dark-purple;
    color: $dark-purple;
}

