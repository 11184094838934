@import "./theme/colors";
@import "./theme/layout"; 
@import "./theme/input"; 

.Search {
    @extend .Component;
    text-align: center;

    .select {
        @extend .select;
    }

    .attributeLists {
        display: inline-flex;

        h1 {
            @extend .header;
        }

        ul {
            @extend .list-style;
            margin-left: 2rem;
            margin-right: 2rem;
        }
    }

    .instructions {
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 1rem;
        border-radius: 1rem;
        width: 50%;
        background-color: $light-purple;
        color: $indigo;
        font-size: 1.1rem;
        margin-left: auto;
        margin-right: auto;
    }

    .inputVerb {
        padding-top: 1rem;
        justify-content: center;

        input {
            @extend input;
            display: inline-block;
            margin-bottom: 2rem;
            max-width: 20rem;
            font-weight: bold;
        }

        .searchButton {
            @extend .icon-button;
            padding: 1rem;
            transform: translate(0%, 12%);
            margin-left: 0.5rem;
            font-size: 4rem;
            color: white;
        }
    }

    .analysisButtons {
        padding-bottom: 2rem;
        button {
            @extend button;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}

