@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Quiz {
    @extend .Component;
    text-align: center;

    .select {
        @extend .select;
    }

    .question {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;
        justify-items: center;
        margin-top: 2rem;
        margin-bottom: 3rem;
        height: 18rem;

        .explanation {
            grid-column: 1;
            font-family: "Solway", serif;
            font-size: 1rem;
            background-color: #dadada;
            padding: 1rem;
            border-radius: 1rem;
            float: left;
            width: 22rem;
            height: 19.5rem;
            overflow: hidden;
            .pages {
                display: inline-block;
                b {
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }

            .gallery {
                padding-top: 1rem;
                padding-bottom: 1rem;

                .raw {
                    width: 20rem;
                }
            }
        }

        .card-container {
            grid-column: 2;
            width: 20rem;
        }

        .choices {
            @extend .list-style;
            font-family: "Solway", serif;
            font-size: 1.5rem;
            grid-column: 3;
            justify-self: center;
            li {
                width: 13rem;
            }
        }
    }

    .quizhead {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        border-bottom: solid 2px #dadada;
        padding: 0.5rem;
        margin-bottom: 3rem;
        overflow: hidden;
        h3,
        .score,
        .hint {
            @extend .header;
        }
        .hint {
            width: 1rem; // make smaller - add an icon too
        }
    }

    .continue {
        border-top: solid 2px #dadada;
        padding-top: 1rem;
    }

    button {
        @extend button;
        width: 10rem;
        font-weight: bold;
        margin-bottom: 1rem;
        background-color: $light-blue;
        color: $indigo;
        border-width: 0;
        &:hover {
            background-color: $light-purple;
        }
    }

    .next {
        background-color: $light-blue;
    }

    .next-correct {
        background-color: #48c984;
    }
}
