@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Explore {
    @extend .Component;
    @extend .select; // generalize
    margin-top: 1rem;
    padding: 1rem;
    margin-bottom: 0;

    .head {
        display: flex;
        justify-content: left;
        border-bottom: 2px lightgrey solid;
        svg {
            color: lightgrey;
            font-size: 2.5rem;
            margin: 0.5rem;
            cursor: pointer;
            &:hover {
                color: $light-purple;
            }
        }
        .title {
            @extend .header;
            font-weight: bold;
            padding: 0.6rem 1rem 0.5rem 0.5rem;
        }
    }

    .listOfUsers {
        list-style-type: none;
        li {
            display: flex;
            margin: 1rem 0 1rem 0;
            padding: 1rem;
            background-color: $light-grey;
            border-radius: 0.5rem;
            &:hover {
                background-color: $light-purple;
                cursor: pointer;
            }
            span {
                width: fit-content;
                min-width: 8rem;
            }
            .username {
                font-weight: bold;
                color: $dark-purple;
            }
            .numberOf {
                margin-left: 1rem;
            }
        }
    }
}
