// Commonly used colors

// For buttons usually
$light-purple: hsl(255, 90%, 92%); //#d4c6ff;
$light-purple-trans: hsla(255, 90%, 92%, 0.3);
$light-purple-overlay: hsla(255, 90%, 92%, 0.8);
$light-blue: hsl(236, 100%, 78%);
$lighter-blue: hsl(236, 91%, 91%);
$light-blue-overlay: hsla(236, 100%, 78%, 0.7);

// For header text and navbar
$dark-purple: hsl(275, 100%, 25%);
$indigo: hsl(274, 100%, 11%);

$navy-blue: hsl(245, 100%, 25%);

// Greys
$light-grey: hsl(0, 0%, 92%);

// Reds
$salmon: hsl(0, 100%, 88%);
$delete: hsl(0, 100%, 62%);

// Green
$light-green: hsl(105, 100%, 91%);

$correct-green: hsla(148, 54%, 54%, 0.5);
$incorrect-red: hsla(0, 100%, 78%, 0.5);
