@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Racetrack {
    margin: 1rem;
    margin-bottom: 4rem;
    .track {
        margin: 4rem;
        user-select: none;
        display: inline-block;
        height: 8px;
        width: 80%;
        margin: 0 5%;
        background-color: $light-purple;

        .handle {
            user-select: none;
            background: $light-purple;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 100%;
            font-size: 0.75rem;
            white-space: nowrap;
            color: $dark-purple;
            .status {
                font-size: 1.5rem;
                margin-top: 1.25rem;
            }
            .name {
                transform-origin: center;
            }
        }
    }
}
