@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Nav {
    display: flex;
    background-color: $indigo;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    height: 3.5rem;
    max-height: 3.5rem;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;

    .logo {
        font-family: 'Solway', serif;
        color: $light-purple;
        text-shadow: $dark-purple 3px 3px;
        font-size: 1.75rem;
        margin-left: 1.5rem;
    }

    .menu {
        display: flex;
        a {
            padding: 0.6rem;
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            border-radius: 0.5rem;
            color: whitesmoke;
            transition: 0.2s;
            &:hover {
                background-color: $navy-blue;
                color: $light-purple;
                text-decoration: none;
            }
        }
        .selected {
            background-color: $dark-purple;
        }
    }

    
    .options {
        margin-right: 1rem;
        #account {
            align-self: center;
            font-weight: lighter;
            color: whitesmoke;
            font-size: 0.9rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            &:hover {
                text-decoration: none;
                color: $light-purple;
            }
        }

        select {
            font-size: 0.9rem;
            border-radius: 0.25rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            padding-left: 0.25rem;
            padding-bottom: 0.2rem;
            border: 0;
        }

        button {
            @extend button;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            background-color: $light-blue;
            border-width: 0;
            color: $indigo;
            padding-top: 0.3rem;
            &:hover {
                border-width: 0;
                background-color: $light-purple;
                color: $indigo;
            }
        }
    }
}