@import "./colors";
@import "./theme/input";

.flashcard {
    font-family: 'Solway', serif;
    font-size: 1.3rem;
    background-color:whitesmoke;
    border: 0.65rem solid $lighter-blue;
    border-radius: 0.75rem;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 4px 8px #0000001a;
    overflow: hidden;

    .upper {
        color: grey;
        min-height: 5rem;
        height: auto; 
        overflow: hidden;
    } 

    .lower {
        font-weight: bold;
        height: 2rem;
        //height: auto; 
        font-size: 1.5rem;
        color: $dark-purple;
    }

    hr {
        border-top: 2px dashed darkgrey;
    }

    input {
        font-size: 1.3rem;
    }
}