@import "./theme/colors";
@import "./theme/layout";
@import "./theme/input";

.Versus {
    @extend .Component;
    text-align: center;
    background: linear-gradient(-45deg, #eaafc8, $light-purple, $light-blue, #654ea3);
    height: 100vh;
    min-width: none;
    max-width: none;
    width: 100%;
    margin: 0;
    padding-top: 2rem;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;

    .select {
        @extend .select;
        background-color: hsla(0, 0%, 96%, 0.6);
        min-height: 42rem;
        width: 65%;
        min-width: 55rem;
        margin: auto;
        // min-width: 50rem;
    }

    .head {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid $dark-purple;
        .corner-area {
            width: 5rem;
        }
        .back {
            font-size: 2rem;
            margin-top: 0.25rem;
            color: $light-blue;
            margin-right: 3rem;
        }
        svg {
            font-size: 2rem;
        }
        button {
            height: 2.75rem;
        }
    }

    .versus-home {
        .join-room-path,
        .create-room-path {
            margin-top: 9rem;
        }
    }

    .room {
        margin-top: 1rem;
        .player-list {
            text-align: left;
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                .placement {
                    font-weight: bold;
                }
            }
        }
        h2 {
            font-family: "Solway", serif;
        }
        .settings {
            margin-top: 4rem;
            margin-bottom: 5rem;
            height: 14rem;
            .deck-list {
                display: flex;
                background-color: $light-purple;
                padding: 1rem;
                border-radius: 1rem;
                margin-bottom: 1rem;
                border: 1px dashed $light-blue;
                h4 {
                    margin: 0.5rem;
                    width: 8rem;
                    text-align: left;
                }
                button {
                    height: 3.75rem;
                    font-size: 1.25rem;
                }

                .chosen-deck-names {
                    display: flex;
                    font-weight: bold;
                    list-style-type: none;
                    margin-top: 0.5rem;
                    margin-left: 1rem;
                    align-items: center;
                    // padding: 0;
                    li {
                        background-color: whitesmoke;
                        padding: 0.5rem;
                        margin-right: 0.75rem;
                        border-radius: 0.5rem;
                        color: $dark-purple;
                    }
                    .number-of-cards {
                        font-size: 0.85rem;
                        background-color: transparent;
                        padding-left: 0;
                        margin: 0;
                    }
                }
            }
            .rules-row {
                background-color: $light-purple;
                padding: 1rem;
                border-radius: 1rem;
                border: 1px dashed $light-blue;
                h4 {
                    margin: 0.5rem;
                    width: 8rem;
                    text-align: left;
                }
                display: flex;
                button {
                    @extend .blueButton;
                    margin-left: 1rem;
                    margin-right: 1rem;
                    height: 3rem;
                }
                .chosen {
                    @extend .blueButton;
                    opacity: 0.7;
                    border: 3px solid $indigo;
                }

                .chosen-mode {
                    font-weight: bold;
                    color: $dark-purple;
                    padding: 0.5rem 1rem;
                    background-color: whitesmoke;
                    border-radius: 0.5rem;
                }
            }
            .quizType {
                @extend .rules-row;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
            .race-with {
                @extend .rules-row;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }

    .ready-and-waiting {
        margin: 0.75rem;
    }

    h1 {
        @extend .header;
    }

    button {
        @extend button;
    }

    input {
        @extend input;
        font-size: 1.5rem;
        width: 12rem;
        height: 2.5rem;
        margin-right: 1rem;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
