@import "./theme/colors";
@import "./theme/layout"; 
@import "./theme/input"; 

.ConfirmDelete {
    @extend .overlay;

    .window {
        border-style: solid;
        border-width: 1.25px;
        border-color: $dark-purple;
        border-radius: 1rem;
        padding: 1rem; 
        width: auto;
        text-align: center;
        background: whitesmoke;

        button {
            @extend button;
            margin: 0.75rem;
            margin-bottom: 0;
            width: 5rem;
            border-radius: 20px;
            &:hover {
                border-radius: 20px;
            }
        }
    }
}